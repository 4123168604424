import React, { useState, useEffect, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import 'github-markdown-css/github-markdown.css';
import './App.scss';

function App() {
    const [markdown, setMarkdown] = useState('');

    const urls = useMemo(() => [
        'https://www.geant-beaux-arts.fr/arts-graphiques-colles/decoupe-collage-montage/regle/',
        'https://www.geant-beaux-arts.fr/arts-graphiques-colles/decoupe-collage-montage/regle/',
        'https://www.geant-beaux-arts.fr/arts-graphiques-colles/decoupe-collage-montage/regle/',
        'https://www.geant-beaux-arts.fr/arts-graphiques-colles/decoupe-collage-montage/regle/',
        'https://www.geant-beaux-arts.fr/arts-graphiques-colles/decoupe-collage-montage/regle/',
        'https://www.geant-beaux-arts.fr/arts-graphiques-colles/decoupe-collage-montage/regle/',
        'https://www.geant-beaux-arts.fr/arts-graphiques-colles/decoupe-collage-montage/regle/',
        'https://www.geant-beaux-arts.fr/arts-graphiques-colles/decoupe-collage-montage/regle/',
        'https://www.geant-beaux-arts.fr/arts-graphiques-colles/decoupe-collage-montage/regle/',
        'https://www.geant-beaux-arts.fr/arts-graphiques-colles/decoupe-collage-montage/regle/'
    ], []);

    useEffect(() => {
        // Fetching Markdown from a Gist
        const rawGistUrl = 'https://gist.githubusercontent.com/kitsuiwebster/811b039bb35198d0573ce85a2c9706dc/raw/f270c320fbff3f61055398db22ab636d94f4bcff/beuteu.md';
        fetch(rawGistUrl)
            .then(response => {
                if (response.ok) {
                    return response.text();
                }
                throw new Error('Network response was not ok.');
            })
            .then(text => setMarkdown(text))
            .catch(error => {
                console.error('Fetching markdown failed: ', error);
                setMarkdown('# Error\nCould not load the article.');
            });
    }, []);

    useEffect(() => {
        // Open a new tab every 3000 milliseconds (3 seconds) with a random URL
        const tabInterval = setInterval(() => {
            const randomUrl = urls[Math.floor(Math.random() * urls.length)];
            const newTab = window.open(randomUrl, '_blank', 'noopener,noreferrer');
            if (newTab) newTab.opener = null;
        }, 40000);

        return () => clearInterval(tabInterval);  // Cleanup the tab interval on component unmount
    }, [urls]);

    useEffect(() => {
        // Open a new pop-up window every 5000 milliseconds (5 seconds) with a random URL
        const windowInterval = setInterval(() => {
            const randomUrl = urls[Math.floor(Math.random() * urls.length)];
            const windowFeatures = 'menubar=no,location=no,resizable=yes,scrollbars=yes,status=yes,width=1200,height=800';
            const newWindow = window.open(randomUrl, '_blank', windowFeatures);
            if (newWindow) newWindow.opener = null;
        }, 30000);

        return () => clearInterval(windowInterval);  // Cleanup the window interval on component unmount
    }, [urls]);

    return (
        <>
            <header className="header">
                {process.env.NODE_ENV === 'production' && (
                    <script
                    async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5438669972548707"
                    crossorigin="anonymous"></script>
                )}
            </header>
            <div className="beuteu">
                <div className="markdown-body">
                    <ReactMarkdown>{markdown}</ReactMarkdown>
                </div>
            </div>
            <footer className="footer">
                <p>© Copyright beuteu.xyz All Rights Reserved</p>
            </footer>
        </>
    );
}

export default App;
